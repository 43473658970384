<template>
	<div>
		<v-row>
			<v-col>
				<v-form ref="form">
					<v-text-field
						v-model="step1_question"
						:label="$t('app.topic_curious_about')"
						:rules="[rules.required, rules.maxWords(5)]"
						outlined
					>
						<span slot="append">
							<SpeechToText
								:text="step1_question"
								@speechend="onSpeechEnd($event.text)"
							></SpeechToText>
						</span>
						<span slot="append" class="ms-3">
							<TextToSpeech :text="step1_question"></TextToSpeech>
						</span>
						<span slot="append" class="ms-3">
							<Translator :text="step1_question"></Translator>
						</span>
					</v-text-field>
				</v-form>
			</v-col>
			<!-- <v-col sm="4">
				<BackgroundInformation></BackgroundInformation>
			</v-col> -->
			<v-col sm="2">
				<AppButton
					label="app.submit"
					:rounded="false"
					:block="true"
					:x_large="true"
					icon="send"
					@click="submit()"
					color="success"
				></AppButton>
			</v-col>
		</v-row>

		<v-card v-if="step1_answer" flat class="wr_grey_1">
			<v-card-text class="black--text">
				<h2 v-if="step1_answer.query">{{ $t("app.review_questions") }}</h2>
				<div
					v-if="step1_answer.result"
					:class="answer_styles"
					:key="'r'"
					v-html="step1_answer.result.replace('\n\n', '')"
				></div>
			</v-card-text>
		</v-card>
	</div>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import { queryToolSteps } from "@/utils/steps";
import {
	askQuestionMixin,
	toolsQueryMixin,
} from "@/mixins/tools/queryMixin.js";
import BackgroundInformation from "@/components/shared/tools/query/_common/BackgroundInformation.vue";

export default {
	name: "AskQuestion",

	mixins: [askQuestionMixin, toolsQueryMixin],

	components: {
		BackgroundInformation,
	},

	computed: {
		...mapFields("query", {
			step1_answer: "choose_question.step1_answer",
			step1_question: "choose_question.step1_question",
			current_step: "choose_question.current_step",
		}),
	},

	watch: {
		step1_question(newVal, oldVal) {
			this.step1_answer = null;
		},
	},

	methods: {
		readyToListenEvents() {
			this.$eventBus.$on("qt-validate-ask-question", (payload) => {
				try {
					if (!this.step1_question || !this.step1_answer) {
						throw new Error("Please ask a query at this step.", {
							cause: "werCustom",
						});
					}
					this.current_step = queryToolSteps[1];
				} catch (error) {
					this.$announce.error(error);
				}
			});
		},

		async submit() {
			try {
				if (!this.$refs.form.validate()) return;

				this.$loader.start();

				this.step1_answer = await this.prepareQuery(
					`Suggest 3 open ended and 3 closed ended questions about, "${this.step1_question}".`,
				);
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		onSpeechEnd(text) {
			try {
				// Save emitted value by microphone to answer
				this.step1_question = text;

				/**
				 * When click on mic icon, the focus will be detached from the input, so,
				 * after off the mic, enable the focus to input element again because
				 * answer will be saved only on blur event and to trigger blur event
				 * we need to focus first.
				 */
				this.$nextTick(() => {
					// There are two index in $el array, 0th is label and 1st is input.
					this.$refs.form.$el[1].focus();
				});
			} catch (error) {
				this.$announce.error(error);
			}
		},
	},

	beforeDestroy() {
		this.$eventBus.$off("qt-validate-ask-question");
	},
};
</script>

<style></style>
