<template>
	<div>
		<v-select
			v-if="$route.name == 'ChooseQuestionQuery'"
			v-model="choose_question_grade"
			:items="grades"
			:label="$t('app.select_grade')"
			outlined
			:rules="[rules.required]"
		></v-select>

		<v-select
			v-if="$route.name == 'MindmapQuery'"
			v-model="mindmap_grade"
			:items="grades"
			:label="$t('app.select_grade')"
			outlined
			:rules="[rules.required]"
		></v-select>

		<v-select
			v-else
			v-model="toc_grade"
			:items="grades"
			:label="$t('app.select_grade')"
			outlined
			:rules="[rules.required]"
		></v-select>
	</div>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import rules from "@/utils/validation";

export default {
	name: "BackgroundInformation",

	data() {
		return {
			rules,
		};
	},

	computed: {
		...mapFields("query", {
			mindmap_grade: "mindmap.grade",
			choose_question_grade: "choose_question.grade",
			toc_grade: "toc.grade",
		}),

		grades() {
			let arr = [];
			for (let index = 1; index <= 12; index++) {
				arr.push("Grade " + index);
			}
			return arr;
		},
	},
};
</script>

<style></style>
