<template>
	<div flat color="wr_grey_1">
		<v-list subheader two-line>
			<v-list-item
				v-for="(item, index) in actions"
				:key="item.title"
				:class="[`${item.color} lighten-4`, 'my-3']"
			>
				<v-list-item-action>
					<v-btn fab small depressed dark class="subtitle-1"
						>{{ index + 1 }}.
					</v-btn>
				</v-list-item-action>

				<v-list-item-content>
					<v-list-item-title
						v-text="$t(item.title)"
						class="font-weight-bold"
					></v-list-item-title>
				</v-list-item-content>

				<v-list-item-action>
					<v-btn fab small @click="$emit('redirect-to', item.value)">
						<v-icon>
							{{
								$vuetify.icons.values[
									$vuetify.rtl ? "left_arrow" : "right_arrow"
								]
							}}
						</v-icon>
					</v-btn>
				</v-list-item-action>
			</v-list-item>
		</v-list>
	</div>
</template>

<script>
export default {
	name: "QueryActions",

	props: {
		actions: {
			required: true,
		},
	},
};
</script>
