<template>
	<v-card flat class="wr_grey_1">
		<v-card-text>
			<component
				v-if="current_step"
				:is="current_step.component_name"
			></component>
		</v-card-text>
	</v-card>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import AskQuestion from "@/components/shared/tools/query/chooseQuestion/steps/AskQuestion.vue";
import Feedback from "@/components/shared/tools/query/chooseQuestion/steps/Feedback.vue";
import TakeActions from "@/components/shared/tools/query/chooseQuestion/steps/TakeActions.vue";
import ShowAnswers from "@/components/shared/tools/query/chooseQuestion/steps/ShowAnswers.vue";

export default {
	name: "Query",

	components: {
		AskQuestion,
		Feedback,
		TakeActions,
		ShowAnswers,
	},

	computed: {
		...mapFields("query", {
			current_step: "choose_question.current_step",
		}),
	},
};
</script>

<style></style>
