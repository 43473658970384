<template>
	<div>
		<v-form ref="form">
			<v-checkbox
				v-for="(item, index) in queries"
				:key="index"
				v-model="multiple"
				:value="item"
				:label="item"
				@change="resetQuery()"
			>
			</v-checkbox>
		</v-form>

		<div align="center">
			<AppButton
				label="app.submit"
				icon="send"
				:class="['mt-2']"
				@click="submit()"
				color="success"
			></AppButton>
		</div>

		<v-card v-if="step4_answer" flat class="wr_grey_1">
			<v-card-text class="black--text">
				<h2 v-if="step4_answer.query">{{ $t("app.review_the_answers") }}</h2>
				<div
					v-if="step4_answer.result"
					:class="answer_styles"
					:key="'r'"
					v-html="step4_answer.result.replace('\n\n', '')"
				></div>
			</v-card-text>
		</v-card>
	</div>
</template>

<script>
import { mapState } from "vuex";
import { mapFields } from "vuex-map-fields";
import rules from "@/utils/validation";
import { queryToolSteps } from "@/utils/steps";
import { toolsQueryMixin } from "@/mixins/tools/queryMixin.js";

export default {
	name: "ShowAnswers",

	mixins: [toolsQueryMixin],

	data() {
		return {
			rules,
			queries: [],
		};
	},

	computed: {
		...mapState({
			step2_questions: (state) => state.query.choose_question.step2_questions,
			step1_answer: (state) => state.query.choose_question.step1_answer,
		}),

		...mapFields("query", {
			step4_answer: "choose_question.step4_answer",
			multiple: "choose_question.multiple",
			current_step: "choose_question.current_step",
		}),
	},

	mounted() {
		this.init();
	},

	methods: {
		readyToListenEvents() {
			this.$eventBus.$on("qt-validate-answers", (payload) => {
				try {
					if (!this.step4_answer) {
						throw new Error("Please ask a query at this step.", {
							cause: "werCustom",
						});
					}
					this.current_step = queryToolSteps[3];
				} catch (error) {
					this.$announce.error(error);
				}
			});
		},

		async init() {
			await this.getQuestions();
		},

		resetQuery() {
			this.step4_answer = null;
		},

		async getQuestions() {
			try {
				this.$loader.start();

				this.queries = [];

				this.queries.push(
					this.step2_questions.open_question_1,
					this.step2_questions.open_question_2,
					this.step2_questions.close_question_1,
					this.step2_questions.close_question_2,
				);

				if (!this.step1_answer || !this.step1_answer.result) return;

				// Match the lines which follows the pattern integer.dot.space
				const lines = [
					...this.step1_answer.result.matchAll(/^\d+\.\s+(.+)/gm),
				].map((r) => r[1]);

				this.queries = [...new Set([...this.queries, ...lines])];
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		async submit() {
			try {
				this.$loader.start();

				if (!this.multiple.length || this.multiple.length > 3) {
					throw new Error("Min 1 and Max 3 questions should be selected.", {
						cause: "werCustom",
					});
				}

				this.step4_answer = await this.prepareQuery(
					"Write the text of the question and under each question write their type if it is an open-ended question, closed-ended question, or statement. Under each question write the answer if it is a statement, write 'this is a statement, not a question to answer and don't write an answer to that statement." +
						this.multiple.map((item) => `"${item}" `).toString(),
				);
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},
	},

	beforeDestroy() {
		this.$eventBus.$off("qt-validate-answers");
	},
};
</script>

<style></style>
