<template>
	<div>
		<v-form ref="form">
			<v-text-field
				v-for="(value, key) in step2_questions"
				:key="key"
				outlined
				:label="$t(`app.${splitString(key)}`)"
				v-model="step2_questions[key]"
				:rules="[rules.required]"
			>
				<span slot="append">
					<SpeechToText
						:text="step2_questions[key]"
						@speechend="onSpeechEnd($event.text, key)"
					></SpeechToText>
				</span>
				<span slot="append" class="ms-3">
					<TextToSpeech :text="step2_questions[key]"></TextToSpeech>
				</span>
				<span slot="append" class="ms-3">
					<Translator :text="step2_questions[key]"></Translator> </span
			></v-text-field>
		</v-form>

		<div align="center">
			<AppButton
				label="app.submit"
				icon="send"
				:class="['mt-2']"
				@click="submit()"
				color="success"
			></AppButton>
		</div>

		<v-card v-if="step2_answer" flat class="wr_grey_1">
			<v-card-text class="black--text">
				<h2 v-if="step2_answer.query">{{ $t("app.review_question_type") }}</h2>
				<div
					v-if="step2_answer.result"
					:class="answer_styles"
					:key="'r'"
					v-html="step2_answer.result.replace('\n\n', '')"
				></div>
			</v-card-text>
		</v-card>
	</div>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import { queryToolSteps } from "@/utils/steps";
import {
	askQuestionMixin,
	toolsQueryMixin,
} from "@/mixins/tools/queryMixin.js";

export default {
	name: "Feedback",

	mixins: [askQuestionMixin, toolsQueryMixin],

	computed: {
		...mapFields("query", {
			step2_questions: "choose_question.step2_questions",
			step2_answer: "choose_question.step2_answer",
			current_step: "choose_question.current_step",
		}),

		splitString() {
			return (str) => {
				let lastIndex = str.lastIndexOf("_");
				let result = str.slice(0, lastIndex);
				return result;
			};
		},
	},

	watch: {
		step2_questions: {
			handler() {
				this.step2_answer = null;
			},
			deep: true,
		},
	},

	methods: {
		readyToListenEvents() {
			this.$eventBus.$on("qt-validate-feedback", (payload) => {
				try {
					if (!this.$refs.form.validate() || !this.step2_answer) {
						throw new Error("Please ask a query at this step.", {
							cause: "werCustom",
						});
					}

					this.current_step = queryToolSteps[2];
				} catch (error) {
					this.$announce.error(error);
				}
			});
		},

		async submit() {
			try {
				if (!this.$refs.form.validate()) return;

				this.$loader.start();

				let query =
					"Are these questions open-ended or close-ended or a statement? ";

				[
					this.step2_questions.open_question_1,
					this.step2_questions.open_question_2,
					this.step2_questions.close_question_1,
					this.step2_questions.close_question_2,
				].forEach((item, index) => {
					query += `"${index + 1}. ${item}", `;
				});

				this.step2_answer = await this.prepareQuery(query);
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		onSpeechEnd(text, key) {
			try {
				// Save emitted value by microphone to answer
				this.step2_questions[key] = text;
			} catch (error) {
				this.$announce.error(error);
			}
		},
	},

	beforeDestroy() {
		this.$eventBus.$off("qt-validate-feedback");
	},
};
</script>

<style></style>
